import * as React from 'react'

import ThemeWrapper from '../Theme'
import Layout from '../layout/BaseLayout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <ThemeWrapper>
    <Layout>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  </ThemeWrapper>
)

export default NotFoundPage
